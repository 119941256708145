import React, { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css'; // Importa el CSS de slick-carousel
import 'slick-carousel/slick/slick-theme.css'; // Importa el tema de slick-carousel
import Slider from 'react-slick'; // Importa react-slick
import { useParams, useNavigate } from 'react-router-dom';

import './ProductDetails.css';
import ProductCard from './ProductCard';
import { toast } from 'react-toastify';

import Modal from 'react-modal';
import Select from 'react-select';

import { FaSearchPlus } from 'react-icons/fa'; // Ícono de lupa

Modal.setAppElement('#root');

const ProductDetails = ({ config }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [product, setProduct] = useState({
    images: [],
    size: '',
    color: '',
    category: '',
    name: '',
    price: 0,
    description: '',
  });


  const apiUrl = config.apiUrl;
  const [quantity, setQuantity] = useState(1);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [hasAttemptedAddToCart, setHasAttemptedAddToCart] = useState(false);
  const [autoplay, setAutoplay] = useState(true);

  const [isZoomOpen, setIsZoomOpen] = useState(false); // Estado para abrir/cerrar el modal
  const [currentImage, setCurrentImage] = useState(null); // Imagen seleccionada

  const [isAddToCartDisabled, setIsAddToCartDisabled] = useState(true);

  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");

  const [uniqueColor, setUniqueColor] = useState({});
  const [uniqueSize, setUniqueSize] = useState({});

  const [availableColors, setAvailableColors] = useState([]);
  const [availableSizes, setAvailableSizes] = useState([]);



  const openZoomModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setIsZoomOpen(true);
    document.body.style.overflow = 'hidden'; // Evitar el scroll en el fondo
  };

  const closeZoomModal = () => {
    setIsZoomOpen(false);
    document.body.style.overflow = 'auto'; // Restaurar el scroll
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);


  useEffect(() => {
    console.log('pintado detalles del producto');
    if (availableColors.length === 0 && availableSizes.length === 0) {
      console.log('1');
      setIsAddToCartDisabled(false);
    } else if (availableColors.length === 0 && selectedSize && isVariantInStock()) {
      console.log('2');
      setIsAddToCartDisabled(false);
    } else if (availableSizes.length === 0 && selectedColor && isVariantInStock()) {
      console.log('3');
      setIsAddToCartDisabled(false);
    } else if (selectedColor && selectedSize && isVariantInStock()) {
      console.log('4');
      setIsAddToCartDisabled(false);
    } else {
      console.log('5');
      setIsAddToCartDisabled(true);
    }



  }, [selectedColor, selectedSize, quantity, product]);


  const isVariantInStock = () => {
    return true; // Por ahora, todos los productos tienen stock
  }

  useEffect(() => {
    fetch(`${apiUrl}/api/products/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setProduct(data);

        // Crear los Maps para colores y tamaños
        const uniqueColorsHand = new Map();
        const uniqueSizeHand = new Map();

        // Recorrer las variantes del producto
        data.variants.forEach(variant => {
          const { id, color, size } = variant;

          // Para uniqueColors: si el color ya existe, agregar el id al array, si no, crear nuevo array
          if (!uniqueColorsHand.has(color)) {
            uniqueColorsHand.set(color, [id]);
          } else {
            uniqueColorsHand.get(color).push(id);
          }

          // Para uniqueSize: si el tamaño ya existe, agregar el id al array, si no, crear nuevo array
          if (!uniqueSizeHand.has(size)) {
            uniqueSizeHand.set(size, [id]);
          } else {
            uniqueSizeHand.get(size).push(id);
          }
        });

        // Ahora que ya has procesado todas las variantes, actualiza los estados
        setUniqueColor(uniqueColorsHand);
        setUniqueSize(uniqueSizeHand);
        setAvailableColors(Array.from(uniqueColorsHand.keys()).filter(color => color !== ""));
        setAvailableSizes(Array.from(uniqueSizeHand.keys()).filter(size => size !== ""));

        console.log('Colores: ', Array.from(uniqueColorsHand.keys()));
        console.log('Tamaños: ', Array.from(uniqueSizeHand.keys()));
      })
      .catch((error) => console.error('Error fetching product details:', error));
  }, [id, apiUrl]);



  useEffect(() => {
    if (product.catId) { // Asegurarse de que el producto ya está cargado
      fetch(`${apiUrl}/api/products`)
        .then((response) => response.json())
        .then((data) => {
          const relatedProducts = data.filter((p) => p.catId === product.catId && p.id !== product.id && p.bestFriends === false); // Filtrar productos relacionados y excluir el actual
          setRelatedProducts(relatedProducts);
          console.log('Productos relacionados: ', relatedProducts);
        })
        .catch((error) => console.error('Error fetching related products:', error));
    }
  }, [product, apiUrl]); // Asegurarse de que este efecto depende de `product`

  const formatDescription = (description) => {
    if (typeof description !== 'string') return '';
    return description.split('\n').map((item, index) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ));
  };



  const handleIncrement = () => setQuantity((prev) => prev + 1);
  const handleDecrement = () => {
    if (quantity <= 1) {
      toast.warn('No puedes reducir la cantidad por debajo de 1.');
      return;
    }
    setQuantity(prev => prev - 1);
  };

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const handleAddToCart = () => {
    console.log('Agregando producto al carrito... pero esta desactivado');
    if (isAddToCartDisabled) {
      console.log('Agregando producto al carrito... pero esta desactivado');
      setHasAttemptedAddToCart(true);
      return;
    } else {
      console.log('Agregando producto al carrito...');
      const finalColor = selectedColor ? selectedColor.value : 'Normal';
      const finalSize = selectedSize ? selectedSize.value : 'Normal';
      setHasAttemptedAddToCart(false);
      const email = getCookie('userEmail');
      const cartItem = {
        product: { id: product.id, name: product.name, price: product.price, images: product.images },
        color: finalColor,
        size: finalSize,
        quantity: quantity,
      };

      if (email) {
        fetch(`${apiUrl}/api/carts/email/${email}/items`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(cartItem),
        })
          .then((response) => {
            if (response.ok) {
              const toastId = toast.success(
                'Producto añadido al carrito exitosamente.',
                {
                  onClick: () => {
                    toast.dismiss(toastId); // Cierra el toast al hacer clic
                    navigate('/cart');      // Redirige a /cart
                  }
                }
              );
            } else {
              throw new Error('Error al añadir el producto al carrito.');
            }
          })
          .catch((error) => {
            console.error('Error:', error);
            toast.error('Hubo un problema al añadir el producto al carrito.');
          });
      } else {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        cart.push(cartItem);
        localStorage.setItem('cart', JSON.stringify(cart));
        const toastId = toast.success(
          'Producto añadido al carrito exitosamente.',
          {
            onClick: () => {
              toast.dismiss(toastId); // Cierra el toast al hacer clic
              navigate('/cart');      // Redirige a /cart
            }
          }
        );
      }
    }
  };

  const handleBuyNow = () => {
    if (isAddToCartDisabled) return;
    handleAddToCart();
    navigate('/cart');
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);

    // Obtener los IDs asociados al color seleccionado
    const colorVariantIds = uniqueColor.get(color);
    console.log('IDs asociados al color seleccionado:', colorVariantIds);

    // Filtrar las tallas disponibles que contengan esos IDs
    const filteredSizes = Array.from(uniqueSize.entries())
      .filter(([size, ids]) => ids.some(id => colorVariantIds.includes(id)))
      .map(([size]) => size)
      .filter(size => size !== "");  // Filtro para evitar tamaños vacíos

    // Actualizar las tallas disponibles
    setAvailableSizes(filteredSizes);
    if (filteredSizes.length === 0) {
      setIsAddToCartDisabled(false);
    } if (filteredSizes.length > 0 && selectedSize) {
      setIsAddToCartDisabled(false);
    }

    console.log('Tamaños disponibles para el color seleccionado:', filteredSizes);
  };



  const handleSizeSelect = (size) => {
    setSelectedSize(size);
    // Obtener los IDs asociados al color seleccionado
    const sizeVariantIds = uniqueSize.get(size);
    console.log('IDs asociados al tamaño seleccionado:', sizeVariantIds);

    // Filtrar las tallas disponibles que contengan esos IDs
    const filteredColors = Array.from(uniqueColor.entries())
      .filter(([color, ids]) => ids.some(id => sizeVariantIds.includes(id)))
      .map(([color]) => color)
      .filter(color => color !== "");  // Filtro para evitar tamaños vacíos

    // Actualizar las tallas disponibles
    setAvailableColors(filteredColors);

    console.log('Tamaños disponibles para el color seleccionado:', filteredColors);
  };

  // Configuración del Slider de react-slick
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: autoplay,
    autoplaySpeed: 3000,
    beforeChange: () => setAutoplay(true), // Asegura que el autoplay se reinicie
  };

  /*
  const isVariantInStock = () => {
    // Si no hay colores y tamaños disponibles, no hay stock
    if (availableColors.length === 0 && availableSizes.length === 0) {
      return true;
    }
  
    // Si no hay colores disponibles, verifica si hay stock para el tamaño seleccionado
    if (availableColors.length === 0 && selectedSize) {
      const variant = product.variants.find(variant =>
        variant.size.toLowerCase() === selectedSize.toLowerCase()
      );
      console.log(variant);
      return variant ? variant.stock > 0 : false;
    }
  
    // Si no hay tamaños disponibles, verifica si hay stock para el color seleccionado
    if (availableSizes.length === 0 && selectedColor) {
      const variant = product.variants.find(variant =>
        variant.color.toLowerCase() === selectedColor.toLowerCase()
      );
      console.log("variantes: ",variant);
      console.log("variantes: ",variant.stock >0);
      return variant ? variant.stock > 0 : false;
    }
  
    // Si hay tanto color como tamaño seleccionados, verifica el stock para esa variante
    if (selectedColor && selectedSize) {
      const variant = product.variants.find(variant =>
        variant.color.toLowerCase() === selectedColor.toLowerCase() &&
        variant.size.toLowerCase() === selectedSize.toLowerCase()
      );
      return variant ? variant.stock > 0 : false;
    }
  
    return false; // Si no se cumple ninguna de las condiciones anteriores, no hay stock
  };

  */


  return (
    <div className="product-details">
      <div className="product-main-content">
        <div className="product-carousel">
          {product.images && product.images.length > 0 ? (
            product.images.length > 1 ? (
              <Slider {...settings}>
                {product.images.map((imageUrl, index) => (
                  <div key={index}>
                    <img
                      src={`${config.apiUrl}${imageUrl}`}
                      alt={`${product.name} - ${index + 1}`}
                    />
                    <div className="zoom-icon" onClick={() => openZoomModal(`${config.apiUrl}${imageUrl}`)}>
                      <FaSearchPlus size={24} />
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              // Solo una imagen, no usar slider
              <div>
                <img
                  src={`${config.apiUrl}${product.images[0]}`}
                  alt={`${product.name}`}
                />
                <div className="zoom-icon" onClick={() => openZoomModal(`${config.apiUrl}${product.images[0]}`)}>
                  <FaSearchPlus size={24} />
                </div>
              </div>
            )
          ) : (
            <div>No images available</div>
          )}
        </div>

        <Modal
          isOpen={isZoomOpen}
          onRequestClose={closeZoomModal}
          contentLabel="Zoom Modal"
          className={`zoom-modal ${isZoomOpen ? 'show' : ''}`}
          overlayClassName={`zoom-modal-overlay ${isZoomOpen ? 'show' : ''}`}
        >
          <button onClick={closeZoomModal} className="close-modal">
            Cerrar
          </button>
          {currentImage && (
            <div className="zoomed-image-container">
              <img src={currentImage} alt="Zoomed" />
            </div>
          )}
        </Modal>

        {product.locker ? '' : (<div className="product-info">
          <h1>{product.name}</h1>
          <p className="price">{product.price} €</p>
          <p>{formatDescription(product.description)}</p>

          {availableColors.length > 0 && <Select
            className='colorSelect'
            placeholder='Select Color'
            options={availableColors.map((color) => ({ value: color, label: color }))}
            onChange={(selectedOption) => handleColorSelect(selectedOption.value)}
            menuPlacement="bottom" // Forzar hacia abajo
            menuPosition="relative" // Colocar relativo al contenedor
            isSearchable={false} // Deshabilitar escritura
          />}
          {availableSizes.length > 0 && <Select
            className='sizeSelect'
            placeholder='Select Size'
            options={availableSizes.map((size) => ({ value: size, label: size }))}
            onChange={(selectedOption) => handleSizeSelect(selectedOption.value)}
            menuPlacement="bottom" // Forzar hacia abajo
            menuPosition="relative" // Colocar relativo al contenedor
            isSearchable={false} // Deshabilitar escritura
          />}

          {hasAttemptedAddToCart && (
            <p className="error-message">Por favor, selecciona un color y un tamaño disponibles.</p>
          )}

          <div className="quantity-selector">
            <button onClick={handleDecrement}>-</button>
            <span>{quantity}</span>
            <button onClick={handleIncrement}>+</button>
          </div>

          <div className="action-buttons">
            <button
              className="add-to-cart"
              onClick={handleAddToCart}
              disabled={isAddToCartDisabled}
            >
              Añadir al carrito
            </button>
            <button
              className="buy-now"
              onClick={handleBuyNow}
              disabled={isAddToCartDisabled}
            >
              Comprar ahora
            </button>
          </div>
        </div>)}
      </div>

      <div className="related-products">
        <h2>Productos Relacionados</h2>
        <div className="related-products-container">
          {relatedProducts
            .map((relatedProduct) => (
              <ProductCard
                key={relatedProduct.id}
                title={relatedProduct.name}
                price={relatedProduct.price}
                image={
                  relatedProduct.images && relatedProduct.images.length > 0
                    ? `${config.apiUrl}${relatedProduct.images[0]}` // Usar la URL de la imagen almacenada
                    : '' // Si no hay imagen, dejar vacío
                }
                onClick={() => handleProductClick(relatedProduct.id)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;




